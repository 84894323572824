

















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Card from '../../shared/src/components/Card.vue'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import Btn from '../../shared/src/components/Btn.vue'
import TextField from '../../shared/src/components/TextField.vue'
import Select from '../../shared/src/components/Select.vue'
import Logo from '../components/Logo.vue'
import ContentRow from '../components/ContentRow.vue'
import Sizzle from '../components/Sizzle.vue'
import Lead from '../helpers/Lead'
import hop from '../../shared/src/helpers/hop'
import firebase from 'firebase'
import { makeTsId } from '../../shared/src/helpers/getUniqueId'
import { formatPhoneNumber, formatPrice } from '../../shared/src/helpers/numFormat'

firebase.initializeApp({
  apiKey: 'AIzaSyA_zQqeI-el1HD0cNeusBLWIB2h3RDdwRA',
  authDomain: 'my-allcourts.firebaseapp.com',
  projectId: 'my-allcourts',
  storageBucket: 'my-allcourts.appspot.com',
  messagingSenderId: '1091894584279',
  appId: '1:1091894584279:web:f497a38096b293e67b6192',
  measurementId: 'G-5CPR850XF8'
})

const onSubmit = (lead, note) => {
  let DB: firebase.firestore.Firestore = firebase.firestore()
  lead.id = makeTsId()
  const data = lead.data()
  data.note = note
  console.log(`emailing ${lead.id}`)
  DB.doc(`/leads/${lead.id}`)
    .set(data)
    .then(() => {
      console.log('lead created')
      return DB.doc(`/automail/${lead.id}`)
        .set({
          to: ['wes@all-courts.com', 'becky.jones@all-courts.com', 'chris@all-courts.com'],
          message: {
            subject: `New Lead ${lead.firstName} ${lead.lastName}`,
            text: `New Lead\nName: ${lead.firstName} ${lead.lastName}\nPhon: ${formatPhoneNumber(lead.phone)}\nEmail: ${
              lead.email
            }\nAddress: ${lead.address.street} ${lead.address.city}, ${lead.address.state} ${lead.address.zip}\nNote: ${
              lead.courtSize
            }\nFrom: All-Courts™ Website\n\n`,
            html: `New Lead\nName: ${lead.firstName} ${lead.lastName}<br>\nPhon: ${formatPhoneNumber(
              lead.phone
            )}<br>\nEmail: ${lead.email}<br>\nAddress: ${lead.address.street} ${lead.address.city}, ${
              lead.address.state
            } ${lead.address.zip}<br>\nNote: ${lead.courtSize}<br>\nFrom: All-Courts™ Website\n\n`
          }
        })
        .then(() => {
          console.log('email sent')
        })
        .catch((e) => 'Email failed: ' + e)
    })
  // update db for account so it knows that leads have changed.
  DB.doc(`/accounts/AKT0CJFJE`).set({ lastModified: { leads: Date.now() } }, { merge: true })
}

@Component({
  components: {
    Sizzle,
    Card,
    Col,
    Row,
    Btn,
    Logo,
    ContentRow,
    TextField,
    Select
  }
})
export default class ContactForm extends Vue {
  @Prop({ default: '' }) discount!: string
  @Prop() miniForm!: boolean
  @Prop() terms!: string
  frameLoaded = false
  submitted = false
  code = ''
  error = ''
  bot = false
  lead = new Lead({ address: { state: 'UT' } })
  states = [
    { value: 'AZ', text: 'AZ' },
    { value: 'UT', text: 'UT' }
  ]
  courtSizes = [
    { value: 'pickleball', text: "Pickleball Size (30'x60')" },
    { value: 'basketball', text: "Full Basketball Size (30'x84')" },
    { value: 'tennis', text: "Full Tennis Size (60'x120')" },
    { value: 'other', text: 'Unspecified' }
  ]
  buildOptions = [
    { value: 'build', text: 'Build a New Court' },
    { value: 'surface', text: 'Resurface Existing Court' }
  ]
  state = this.states[0].value
  courtSize = this.courtSizes[0].value
  mounted(): void {
    // store the coupon as a cookie
    if (this.$route.params.code) {
      this.code = this.$cookies.get('code')
      const code = (this.$route.params.code || this.code || '').toUpperCase()
      this.$cookies.set('code', code, '')
      this.code = this.$cookies.get('code')
    }
    this.lead['homePhone'] = ''
    const inputs = document.querySelectorAll('input[type="text"')
    setTimeout(() => {
      ;((inputs[2] as HTMLElement).parentNode as HTMLElement).style.display = 'none'
    })
  }
  miniFormExceptions = { email: 1, courtSize: 1, build: 1, address: 1 }
  submit(): void {
    this.error = ''
    if (this.lead['homePhone'] || this.bot) {
      this.bot = true
      this.error = 'All Fields are required.'
      return
    }
    this.lead.createdAt = new Date()
    for (const i in this.lead) {
      if (hop(this.lead, i) && !this.lead[i] && i !== 'id' && i !== 'homePhone' && i !== 'note' && i !== 'coupon') {
        if (!this.miniForm || !this.miniFormExceptions[i]) {
          this.error = 'All Fields are required.'
        }
      }
    }
    if (!this.error) {
      this.submitted = true
      if (this.code) {
        this.lead.coupon = this.code
      }
      onSubmit(this.lead, this.discount)
    }
  }
  formatPrice = formatPrice
}
