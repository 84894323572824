const cache = {}
const getUniqueId = () => {
  const id = (Date.now() + Math.floor(Math.random() * 10)).toString(32)
  if (cache[id]) {
    return getUniqueId()
  }
  cache[id] = 1
  return id
}

export const makeTsId = (): string => {
  return Date.now().toString(36).toUpperCase()
}

export const tsIdToDate = (tsId: string): Date => {
  const d = new Date(parseInt(tsId.toLowerCase(), 36))
  console.log(d)
  return d
}

export default getUniqueId
