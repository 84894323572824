import { tsIdToDate } from '../../shared/src/helpers/getUniqueId'
import { JsonData } from 'shared/src/types/JsonData'
import { Address } from '../../shared/src/types/Address'

export interface Profile {
  id: string
  firstName: string
  lastName: string
  phone: string
  email: string
}

export default class Lead implements Profile {
  id: string
  type: string
  createdAt: Date
  firstName: string
  lastName: string
  phone: string
  email: string
  address: Address | null
  courtSize: string
  build: string
  note: string
  coupon?: string
  constructor(data?: JsonData) {
    this.id = (data?.id || '') as string
    this.type = (data?.type || 'lead') as string
    this.createdAt = this.id ? tsIdToDate(this.id.substr(1, this.id.length)) : new Date()
    this.firstName = (data?.firstName || '') as string
    this.lastName = (data?.lastName || '') as string
    this.phone = (data?.phone || '') as string
    this.email = (data?.email || '') as string
    this.address = (data?.address || {
      street: '',
      state: 'UT',
      zip: ''
    }) as unknown as Address | null
    this.courtSize = ((data?.courtSize || '') as string) || 'pickleball'
    this.build = ((data?.build || '') as string) || 'build'
    this.note = (data?.note as string) || ''
    this.coupon = (data?.coupon as string) || ''
  }

  data(): JsonData {
    return {
      id: this.id,
      type: this.type,
      createdAt: this.createdAt?.getTime(),
      firstName: this.firstName,
      lastName: this.lastName,
      courtSize: this.courtSize,
      build: this.build,
      phone: this.phone,
      email: this.email,
      note: this.note,
      coupon: this.coupon || '',
      address: {
        street: this.address?.street || '',
        city: this.address?.city || '',
        state: this.address?.state || '',
        zip: this.address?.zip || ''
      }
    }
  }
}
