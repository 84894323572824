

















import { Component, Vue, Prop } from 'vue-property-decorator'
import LabelWrap from './LabelWrap.vue'
import Rules from '../helpers/Rules'
import { JsonData, JsonVal } from '../types/JsonData'

@Component({
  components: {
    LabelWrap
  }
})
export default class Select extends Vue {
  @Prop({ default: '' }) label!: string
  @Prop({ default: 'text' }) text!: string
  @Prop({ default: 'value' }) prop!: string
  @Prop({ default: '' }) value!: JsonData | JsonVal
  @Prop({ default: '' }) options!: { text: string; value: string | number }[]
  @Prop({ default: '' }) error!: string
  @Prop({ default: 'blue-500' }) highlight!: string
  @Prop({ default: null }) rules!: Rules[]

  focused = false
  full = false

  get displayValue(): string {
    return this.value?.[this.text] || this.value
  }

  mounted(): void {
    this.onInputChange({ target: this.$refs.input as Vue })
  }

  onInputChange(e: { target: Vue | Element | Vue[] | Element[] }): void {
    const el = e.target as HTMLSelectElement
    const value = this.options[el?.selectedIndex]?.[this.prop]
    this.full = !!value
    this.$emit('input', value)
  }
}
